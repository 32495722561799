import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import { Lazyload } from 'vant';
import { Dialog } from 'vant';

Vue.use(Vant);

// 全局注册
Vue.use(Dialog);
Vue.use(Lazyload);

// 注册时可以配置额外的选项
Vue.use(Lazyload, {
    lazyComponent: true,
});


Vue.config.productionTip = false


new Vue({
    router,
    render: function(h) { return h(App) }
}).$mount('#app')